<template>
  <div class="medic-search-content">
    <div class="search-box">
      <medic-input :cunstomIndex="form.searchType" :initSearchText="form.searchText" @getSearchContent="getSearchContent" type="small" />
    </div>
    <div class="medic-container">
      <MedicTabs :customIndex="form.medicTabIndex" @getTabIndex="getTabIndex" />
    </div>
    <div class="search-list-content">
      <div class="medic-container">
        <div style="min-height: calc(100vh - 387px);margin-bottom: 34px;">
          <Product :list="productList" :searchText="form.searchText" :isEmpty="isEmpty" @reload="getSearchContent({})" />
        </div>
        <Pagination
          :total="pagination.total"
          :page-size="pagination.pageSize"
          :current-page="pagination.current"
          @current-change="changeCurrent"
          @size-change="changeSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MedicInput from '@/views/herbalMedicine/components/SearchInput'
import Pagination from '@/views/herbalMedicine/components/Pagination'
import Product from '@/views/herbalMedicine/components/ProductList'
import MedicTabs from '@/views/herbalMedicine/components/MedicTabs'
import { poisonDrugSearch, herbalDrugSearch, patentDrugSearch } from '@/api/herbalMedicine'
import eventBus from '@/eventBus'
import axios from 'axios'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      form: {
        medicTabIndex: '-1',
        searchType: '',
        searchText: '',
      },
      pagination: {
        current: 0,
        pageSize: 16,
        total: 0,
      },
      productList: [],
      isEmpty: false
    }
  },
  mounted() {
    eventBus.$on('reload', () => {
      this.getSearchContent({})
    })
    this.initSearchContent();
    this.initSearch();
  },
  methods: {
    // 初始化搜索内容
    initSearchContent() {
      this.form.searchType = this.$route.query.searchType;
      this.form.searchText = this.$route.query.searchText;
      this.form.medicTabIndex = this.form.searchType;
    },
    // 初始化搜索
    initSearch() {
      this.getSearchContent({})
    },
    // 点击搜索
    getSearchContent(scope) {
      Object.assign(this.form, scope)
      // 重置tab选项
      scope.searchType ? this.form.medicTabIndex = scope.searchType : null;
      const parmary = {
        start: this.pagination.current,
        limit: this.pagination.pageSize,
        word: this.form.searchText
      }
      switch (Number(this.$route.query.medicType) || this.medicType) {
        case 0:
          // 首页搜索
          if(this.form.medicTabIndex == '1') {
            // 中草药搜索
            parmary.toxicityLevel = '';
            this.herbalDrugSearch(parmary);
          } else if (this.form.medicTabIndex == '2') {
            // 中成药搜索
            parmary.otc = '';
            this.patentDrugSearch(parmary);
          } else {
            this.poisonDrugSearch(parmary);
          }
          break;
        // 中草药搜索
        case 1:
          parmary.toxicityLevel = this.form.medicTabIndex == '-1' ? '' : Number(this.form.medicTabIndex);
          this.herbalDrugSearch(parmary);
          break;
        // 中成药搜索
        case 2:
          parmary.otc = this.form.medicTabIndex == '-1' ? '' : Number(this.form.medicTabIndex);
          this.patentDrugSearch(parmary);
          break;
        default:
          break;
      }
    },
    /** 搜索有毒药品列表 */
    poisonDrugSearch(scope) {
      console.log('搜索有毒药品列表')
      poisonDrugSearch(scope).then(res => {
        this.productList = res.list
        this.changeEmpty(this.productList)
        this.paginationConfig(res)
      })
    },
    /** 搜索有毒中草药列表 */
    herbalDrugSearch(scope) {
      console.log('搜索有毒中草药列表')
      herbalDrugSearch(scope).then(res => {
        this.productList = res.list
        this.changeEmpty(this.productList)
        this.paginationConfig(res)
      })
    },
    /** 搜索有毒中成药搜索 */
    patentDrugSearch(scope) {
      console.log('搜索有毒中成药搜索')
      patentDrugSearch(scope).then(res => {
        this.productList = res.list
        this.changeEmpty(this.productList)
        this.paginationConfig(res)
      })
    },
    changeEmpty(productList) {
      if(productList.length === 0) 
        this.isEmpty = true
      else 
        this.isEmpty = false
      console.log(this.isEmpty)
    },
    // 处理分页
    paginationConfig(page) {
      this.pagination.pageSize = page.limit;
      this.pagination.total = page.count;
    },
    // 获取getTabIndex
    getTabIndex(index) {
      this.form.medicTabIndex = index;
      // 切换tab 清空页码
      this.pagination.current = 0;
      this.getSearchContent({});
    },
    // 点击下一页
    changeCurrent(size) {
      console.log('点击下一页', size)
      this.pagination.current = size;
      this.getSearchContent({});
    },
    // 切换页面
    changeSize(size) {
      console.log('切换页面', size)
      this.pagination.current = size;
      this.getSearchContent({});
    }
  },
  components: {
    MedicInput,
    Pagination,
    Product,
    MedicTabs
  },
  computed: {
    ...mapState({
      medicType: state => state.medic.medicType
    })
  }
}
</script>

<style lang="scss" scope>
.medic-search-content{
  .search-box{
    width: 740px;
    margin: 30px auto;
  }
  .search-list-content{
    min-height: calc(100vh - 215px);
    padding: 16px 0 87px;
    background: #F7F7F7;
    box-sizing: border-box;
  }
}
</style>